export default class ErrorPage extends PureComponent {

	render() {
		const { children } = this.props;

		// : todo :

		return (
			<div>
				{children}
			</div>
		)
	}
}